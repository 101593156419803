const achievement = {
    state: {
        achievement: {},
    },

    mutations: {
        setDashboardAchievement: (state, payload) => {
            state.achievement = payload.achievement;
        },
    },

    getters: {
        getDashboardAchievement: (state) => {
            return state;
        },
    },

    actions: {
        setDashboardAchievement({ commit }, data) {
            commit("setDashboardAchievement", {
                achievement: data,
            });
        },

        clearDashboardAchievement({ commit }) {
            commit("setDashboardAchievement", {});
        },
    },
};

export { achievement };
