import Vue from "vue";
import VueRouter from "vue-router";
import router from "./router/index";
import axios from "axios";
import { store } from "./store/store";
import VueAxios from "vue-axios";
import VueApexCharts from "vue-apexcharts";
import VueCookies from "vue-cookies";

import BootstrapVue from "bootstrap-vue";
import { InlineSvgPlugin } from "vue-inline-svg";
import VueClipboard from "vue-clipboard2";
import VueSocialSharing from "vue-social-sharing";

import { BSkeleton } from "bootstrap-vue";
import App from "./App.vue";

import "../src/styles/app.scss";

Vue.use(VueRouter);

Vue.use(VueSocialSharing);
Vue.use(BootstrapVue);
Vue.use(InlineSvgPlugin);
Vue.use(VueClipboard);
Vue.use(VueAxios, axios);
Vue.use(VueApexCharts);
Vue.use(VueCookies);
Vue.component("apexchart", VueApexCharts);
Vue.component("b-skeleton", BSkeleton);

axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.interceptors.request.use(
    function(config) {
        const authData = JSON.parse(localStorage.getItem("localStorageStoreData"));

        config.headers = {
            "Content-Type": "application/json",
        };

        if (authData && authData.auth && authData.auth.token) {
            config.headers.Authorization = authData.auth.token.split(" ")[1];
        }

        return config;
    },
    function(error) {
        return Promise.reject(error);
    }
);

Vue.prototype.$apiUrl = process.env.VUE_APP_API_URL;
Vue.prototype.$oldClientUrl = process.env.VUE_APP_OLD_CLIENT_URL;
Vue.prototype.$oldClientTestUrl = process.env.VUE_APP_OLD_CLIENT_TEST_URL;

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount("#app");
