const progress = {
    state: {
        progress          : [],
        additionalProgress: []
    },

    mutations: {
        setUserProgress: (state, payload) => {
            state.progress = payload.progress;
        },

        setUserAdditionalProgress: (state, payload) => {
            state.additionalProgress = payload.additionalProgress;
        },
    },

    getters: {
        getUserProgress: (state) => {
            return state;
        },
    },

    actions: {
        setUserProgress({ commit }, data) {
            commit("setUserProgress", {
                progress: data,
            });
        },

        clearUserProgress({ commit }) {
            commit('setUserProgress', [])
        },

        setUserAdditionalProgress({ commit }, data) {
            commit("setUserAdditionalProgress", {
                additionalProgress: data,
            });
        },

        clearUserAdditionalProgress({ commit }) {
            commit('setUserAdditionalProgress', [])
        }
    },
};

export { progress };
