import Vue from 'vue'
import VueRouter from 'vue-router'
import VueMeta from 'vue-meta'

import routes from './routes'
import { isValidToken } from "../helpers/isValidToken";
import axios from "axios";
import { store } from '../store/store.js'

Vue.use(VueRouter);
Vue.use(VueMeta, {
    keyName: 'page',
});

const router = new VueRouter({
    routes,
    mode: 'history',
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { x: 0, y: 0 }
        }
    },
});

router.beforeEach(async (to, from, next) => {
    if (to.matched.some(m => m.meta.authRequired)) {
        const checkStatusResult = await axios.get('/auth/status');
        if (checkStatusResult && checkStatusResult.data && checkStatusResult.data.authorized) {
            if (to.meta.adminsOnly) {
                if (checkStatusResult.data.role === 'admin') {
                    next();
                } else if (checkStatusResult.data.role === 'user') {
                    next(from.path || '/');
                } else {
                    next(from.path || '/');
                }
            } else {
                if (to.params.slug) {
                    const getNotificationsCount = await axios.get('/notifications/unread');
                    if (getNotificationsCount && getNotificationsCount.data && getNotificationsCount.data.result) {
                        await store.dispatch("setUnreadNotifications", getNotificationsCount.data.unreadNotifications);
                    } else {
                        console.error("Ошибка при получении непрочитанных уведомлений");
                    }
                }

                await axios.post('/ip', {});

                next();
            }
        } else {
            await store.dispatch("logOut");
            next(from.path || '/');
        }
    } else {
        next();
    }
});

export default router
