const notificationSettings = {
    state: {
        notificationSettings: {},
    },

    mutations: {
        setNotificationSettings: (state, payload) => {
            state.notificationSettings = payload.notificationSettings;
        },
    },

    getters: {
        getNotificationSettings: (state) => {
            return state;
        },
    },

    actions: {
        setNotificationSettings({ commit }, data) {
            commit("setNotificationSettings", {
                notificationSettings: data,
            });
        },

        clearNotificationSettings({ commit }) {
            commit("setNotificationSettings", {});
        },
    },
};

export { notificationSettings };
