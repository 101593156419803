const goal = {
    state: {
        goal: {},
    },

    mutations: {
        setDashboardGoal: (state, payload) => {
            state.goal = payload.goal;
        },
    },

    getters: {
        getDashboardGoal: (state) => {
            return state;
        },
    },

    actions: {
        setDashboardGoal({ commit }, data) {
            commit("setDashboardGoal", {
                goal: data,
            });
        },

        clearDashboardGoal({ commit }) {
            commit("setDashboardGoal", {});
        },
    },
};

export { goal };
