const reviewOnboard = {
    state: {
        isReviewOnboard: false,
        isReviewOnboardStudy: false,
        isReviewOnboardLesson: false,
        isReviewOnboardWebinars: false,
        isReviewOnboardBonuses: false,
        isReviewOnboardProfile: false,
    },

    mutations: {
        setReviewOnBoard: (state, payload) => {
            state.isReviewOnboard = payload;
        },
        setReviewOnBoardStudy: (state, payload) => {
            state.isReviewOnboardStudy = payload;
        },
        setReviewOnBoardLesson: (state, payload) => {
            state.isReviewOnboardLesson = payload;
        },
        setReviewOnBoardWebinars: (state, payload) => {
            state.isReviewOnboardWebinars = payload;
        },
        setReviewOnBoardBonuses: (state, payload) => {
            state.isReviewOnboardBonuses = payload;
        },
        setReviewOnBoardProfile: (state, payload) => {
            state.isReviewOnboardProfile = payload;
        },
    },

    getters: {
        getIsReviewOnboard: (state) => {
            return state.isReviewOnboard;
        },
        getIsReviewOnboardStudy: (state) => {
            return state.isReviewOnboardStudy;
        },
        getIsReviewOnboardLesson: (state) => {
            return state.isReviewOnboardLesson;
        },
        getIsReviewOnboardWebinars: (state) => {
            return state.isReviewOnboardWebinars;
        },
        getIsReviewOnboardBonuses: (state) => {
            return state.isReviewOnboardBonuses;
        },
        getIsReviewOnboardProfile: (state) => {
            return state.isReviewOnboardProfile;
        },
    },

    actions: {
        setIsReviewOnBoard({ commit }, payload) {
            commit("setReviewOnBoard", {
                isReviewOnboard: payload,
            });
        },
        setIsReviewOnBoardStudy({ commit }, payload) {
            commit("setReviewOnBoardStudy", {
                isReviewOnboardStudy: payload,
            });
        },
        setIsReviewOnBoardLesson({ commit }, payload) {
            commit("setReviewOnBoardLesson", {
                isReviewOnboardLesson: payload,
            });
        },
        setIsReviewOnBoardWebinars({ commit }, payload) {
            commit("setReviewOnBoardWebinars", {
                isReviewOnboardWebinars: payload,
            });
        },
        setIsReviewOnBoardBonuses({ commit }, payload) {
            commit("setReviewOnBoardBonuses", {
                isReviewOnboardBonuses: payload,
            });
        },
        setIsReviewOnBoardProfile({ commit }, payload) {
            commit("setReviewOnBoardProfile", {
                isReviewOnboardProfile: payload,
            });
        },
    },
};

export { reviewOnboard };
