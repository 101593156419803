const quiz = {
    state: {
        entranceQuiz: [],
        finalQuiz   : [],
    },

    mutations: {
        setEntranceQuiz: (state, payload) => {
            state.entranceQuiz = payload.entranceQuiz;
        },
        setFinalQuiz: (state, payload) => {
            state.finalQuiz = payload.finalQuiz;
        },
    },

    getters: {
        getEntranceQuiz: (state) => {
            return state.entranceQuiz;
        },
        getFinalQuiz: (state) => {
            return state.finalQuiz;
        },
    },

    actions: {
        setEntranceQuiz({ commit }, data) {
            commit("setEntranceQuiz", {
                entranceQuiz: data,
            });
        },

        clearEntranceQuiz({ commit }) {
            commit('setEntranceQuiz', [])
        },

        setFinalQuiz({ commit }, data) {
            commit("setFinalQuiz", {
                finalQuiz: data,
            });
        },

        clearFinalQuiz({ commit }) {
            commit('setFinalQuiz', [])
        },
    },
};

export { quiz };
