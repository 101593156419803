<template>
    <div id="app">
        <RouterView />
    </div>
</template>

<script>

import './plugins/vdo.js';

export default {
    name: "App",
    components: {}
};
</script>

<style></style>
