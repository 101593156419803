const earningPoints = {
    state: {
        earningPoints: {},
    },

    mutations: {
        setEarningPoints: (state, payload) => {
            state.earningPoints = payload.earningPoints;
        },
    },

    getters: {
        getEarningPoints: (state) => {
            return state;
        },
    },

    actions: {
        setEarningPoints({ commit }, data) {
            commit("setEarningPoints", {
                earningPoints: data,
            });
        },

        clearEarningPoints({ commit }) {
            commit("setEarningPoints", {});
        },
    },
};

export { earningPoints };
