const notifications = {
    state: {
        notifications: {},
    },

    mutations: {
        setNotifications: (state, payload) => {
            state.notifications = payload;
        },
        setUnreadNotifications: (state, payload) => {
            state.unread = payload;
        },
        setActionRender: (state, payload) => {
            state.render = payload;
        },
    },

    getters: {
        getNotifications: (state) => {
            return state.notifications;
        },
        getUnreadNotificationsCount: (state) => {
            return state.unread;
        },
        getActionRender: (state) => {
            return state.render;
        },
    },

    actions: {
        setNotifications({ commit }, data) {
            commit("setNotifications", data);
        },

        setUnreadNotifications({ commit }, data) {
            commit("setUnreadNotifications", data);
        },

        setActionRender({ commit }, data) {
            commit("setActionRender", data);
        },

        clearNotifications({commit}) {
            commit('setNotifications', {});
        }
    },
};

export { notifications };
