export const member = {
    state: {
        member: {},
        rules: {},
        confirmations: {},
        isBlockedHomework: false,
        certificate: {},
        usedeskChatToken: ""
    },

    mutations: {
        setMember: (state, member) => {
            state.member = member;
        },

        setRules: (state, rules) => {
            state.rules = rules;
        },

        setConfirmations: (state, confirmations) => {
            state.confirmations = confirmations;
        },

        setIsBlockedHomework: (state, isBlockedHomework) => {
            state.isBlockedHomework = isBlockedHomework;
        },

        setCertificate: (state, certificate) => {
            state.certificate = certificate;
        },

        setUsedeskChatToken: (state, usedeskChatToken) => {
            state.usedeskChatToken = usedeskChatToken;
        },
    },

    getters: {
        getMember: state => {
            return state.member
        },

        getRules: state => {
            return state.rules
        },

        getConfirmations: state => {
            return state.confirmations
        },

        getIsBlockedHomework: state => {
            return state.isBlockedHomework
        },

        getCertificate: state => {
            return state.certificate
        },

        getUsedeskChatToken: state => {
            return state.usedeskChatToken
        },
    },

    actions: {
        setMember({commit}, member) {
            commit('setMember', member)
        },

        clearMember({commit}) {
            commit('setMember', {})
        },

        setRules({commit}, rules) {
            commit('setRules', rules)
        },

        clearRules({commit}) {
            commit('setRules', {})
        },

        setConfirmations({commit}, confirmations) {
            commit('setConfirmations', confirmations)
        },

        clearConfirmations({commit}) {
            commit('setConfirmations', {})
        },

        setIsBlockedHomework({commit}, isBlockedHomework) {
            commit('setIsBlockedHomework', isBlockedHomework)
        },

        clearIsBlockedHomework({commit}) {
            commit('setIsBlockedHomework', false)
        },

        setCertificate({commit}, certificate) {
            commit('setCertificate', certificate)
        },

        clearCertificate({commit}) {
            commit('setCertificate', {})
        },

        setUsedeskChatToken({commit}, usedeskChatToken) {
            commit('setUsedeskChatToken', usedeskChatToken)
        },

        clearUsedeskChatToken({commit}) {
            commit('setUsedeskChatToken', "")
        },
    }
};
