import { isValidToken } from "../../helpers/isValidToken";

export const auth = {
    state: {
        auth: false,
        token: false,
        avatar: '',
        name: '',
        email: '',
    },

    mutations: {
        setAuth: (state, payload) => {
            state.auth = payload.auth;
            state.token = payload.token;
            state.avatar = payload.avatar;
            state.name = payload.name;
            state.email = payload.email;
        },
        setName: (state, payload) => {
            state.name = payload;
        },
        setAvatar: (state, payload) => {
            state.avatar = payload;
        },
    },

    getters: {
        getAuth: state => {
            return state.auth
        },
        getAvatar: state => {
            return state.avatar
        },
        getName: state => {
            return state.name
        },
        getEmail: state => {
            return state.email
        },
        isValidToken: (state) => {
            return isValidToken (state.token);
        }
    },

    actions: {
        auth({commit}, data) {
            commit('setAuth',{
                auth: true,
                token: data.token,
                avatar: data.avatar,
                name: data.name,
                email: data.email
            })
        },

        logOut({commit}) {
            commit('setAuth',{
                auth: false,
                token: false,
                avatar: '',
                name: '',
                email: ''
            })
        },

        setName({commit}, data) {
            commit('setName', data)
        },

        setAvatar({commit}, data) {
            commit('setAvatar', data)
        }
    }
}
