const rating = {
    state: {
        raiting: {},
    },

    mutations: {
        setDashboardRating: (state, payload) => {
            state.rating = payload.rating;
        },
    },

    getters: {
        getDashboardRating: (state) => {
            return state;
        },
    },

    actions: {
        setDashboardRating({ commit }, data) {
            commit("setDashboardRating", {
                rating: data,
            });
        },

        clearDashboardRating({ commit }) {
            commit("setDashboardRating", []);
        },
    },
};

export { rating };
