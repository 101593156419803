const weekPlan = {
    state: {
        weekPlan: {},
    },

    mutations: {
        setWeekPlan: (state, payload) => {
            state.weekPlan = payload;
        },
    },

    getters: {
        getWeekPlan: (state) => {
            return state.weekPlan;
        },
    },

    actions: {
        setWeekPlan({ commit }, data) {
            commit("setWeekPlan", data);
        },

        clearWeekPlan({commit}) {
            commit('setWeekPlan', {});
        }
    },
};

export { weekPlan };
