export function isValidToken(token) {
    if (!token) {
        return false;
    }

    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(Buffer.from(base64, "base64").toString("ascii").split("").map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    const jwtPayload = JSON.parse(jsonPayload);
    return !(jwtPayload.exp < (Date.now() / 1000));
}
