const methodist = {
    state: {
        methodist: {},
    },

    mutations: {
        setDashboardMethodist: (state, methodist) => {
            state.methodist = methodist;
        },
    },

    getters: {
        getDashboardMethodist: (state) => {
            return state.methodist;
        },
    },

    actions: {
        setDashboardMethodist({ commit }, data) {
            commit("setDashboardMethodist", {
                methodist: data,
            });
        },

        clearDashboardMethodist({ commit }) {
            commit("setDashboardMethodist", []);
        },
    },
};

export { methodist };
