import Vue from "vue";
import Vuex from "vuex";
import VuexPersist from "vuex-persist";

import { alert } from "./alert";
import { auth } from "./auth";
import { programs } from "./programs";
import { member } from "./member";
import { progress } from "./progress";
import { webinars } from "./webinars";
import { rating } from "./rating";
import { profile } from "./profile";
import { activityStatus } from "./activityStatus";
import { methodist } from "./methodist";
import { shop } from "./shop";
import { earningPoints } from "./earningPoints";
import { goal } from "./goal";
import { weekPlan } from "./weekPlan";
import { custom } from "./custom";
import { bonuses } from "./bonuses";
import { evaluation } from "./evaluation";
import { review } from "./review";
import { chat } from "./chat";
import { achievement } from "./achievement";
import { onboard } from "./onboard";
import { notifications } from "./notifications";
import { quiz } from "./quiz";
import { reviewOnboard } from "./reviewOnboard";
import { notificationSettings } from "./notificationSettings";

Vue.use(Vuex);

const vuexPersist = new VuexPersist({
    key: "localStorageStoreData",
    storage: window.localStorage,
});

export const store = new Vuex.Store({
    plugins: [vuexPersist.plugin],
    modules: {
        alert: alert,
        auth: auth,
        programs: programs,
        member: member,
        progress: progress,
        webinars: webinars,
        rating: rating,
        profile: profile,
        activityStatus: activityStatus,
        methodist: methodist,
        shop: shop,
        earningPoints: earningPoints,
        goal: goal,
        weekPlan: weekPlan,
        custom: custom,
        bonuses: bonuses,
        evaluation: evaluation,
        review: review,
        chat: chat,
        achievement: achievement,
        onboard: onboard,
        notifications: notifications,
        quiz: quiz,
        reviewOnboard: reviewOnboard,
        notificationSettings: notificationSettings,
    },
});
