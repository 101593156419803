const shop = {
    state: {
        shop: {},
    },

    mutations: {
        setDashboardShop: (state, payload) => {
            state.shop = payload.shop;
        },
    },

    getters: {
        getDashboardShop: (state) => {
            return state;
        },
    },

    actions: {
        setDashboardShop({ commit }, data) {
            commit("setDashboardShop", {
                shop: data,
            });
        },

        clearDashboardShop({ commit }) {
            commit("setDashboardShop", {});
        },
    },
};

export { shop };
