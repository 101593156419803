export const programs = {
    state: {
        programs   : [],
        oldPrograms: [],
        lesson     : {},
    },

    mutations: {
        setUserPrograms: (state, data) => {
            state.programs = data.programs
        },

        setUserOldPrograms: (state, data) => {
            state.oldPrograms = data.oldPrograms
        },

        setLesson: (state, data) => {
            state.lesson = data
        },
    },

    getters: {
        getUserPrograms: state => {
            return state.programs
        },

        getUserOldPrograms: state => {
            return state.oldPrograms
        },

        getLesson: state => {
            return state.lesson
        },
    },

    actions: {
        setUserPrograms({ commit }, data) {
            commit('setUserPrograms', {
                programs: data.programs
            })
        },

        clearUserPrograms({ commit }) {
            commit('setUserPrograms', {
                programs: []
            })
        },

        setUserOldPrograms({ commit }, data) {
            commit('setUserOldPrograms', {
                oldPrograms: data.oldPrograms
            })
        },

        clearUserOldPrograms({ commit }) {
            commit('setUserOldPrograms', {
                oldPrograms: []
            })
        },

        setLesson({ commit }, data) {
            commit('setLesson', {
                lesson: data
            })
        },

        clearLesson({ commit }) {
            commit('setLesson', {
                lesson: []
            })
        },
    }
};
