const onboard = {
    state: {
        isOnboardVideo: false,
    },

    mutations: {
        setOnBoardVideo: (state, payload) => {
            state.isOnboardVideo = payload;
        },
    },

    getters: {
        getIsOnboardVideo: (state) => {
            return state.isOnboardVideo;
        },
    },

    actions: {
        setIsOnBoardVideo({ commit }, payload) {
            commit("setOnBoardVideo", {
                isOnboardVideo: payload,
            });
        },
    },
};

export { onboard };
