export const webinars = {
    state: {
        webinars: [],
        schedule: [],
        selectedWebinar: [],
    },

    mutations: {
        setWebinars: (state, data) => {
            state.webinars = data.webinars;
        },

        setSchedule: (state, data) => {
            state.schedule = data.schedule;
        },
        setWebinar: (state, payload) => {
            state.selectedWebinar = payload;
        },
    },

    getters: {
        getWebinars: (state) => {
            return state.webinars;
        },

        getSchedule: (state) => {
            return state.schedule;
        },

        getSelectedWebinar: (state) => {
            return state.selectedWebinar;
        },
    },

    actions: {
        setWebinars({ commit }, data) {
            commit("setWebinars", {
                webinars: data.webinars,
            });
        },

        clearWebinars({ commit }) {
            commit("setWebinars", {
                webinars: [],
            });
        },

        setSchedule({ commit }, data) {
            commit("setSchedule", {
                schedule: data.schedules,
            });
        },

        clearSchedule({ commit }) {
            commit("setSchedule", {
                schedule: [],
            });
        },

        setSelectedWebinar({ commit }, payload) {
            commit("setWebinar", {
                selectedWebinar: payload,
            });
        },
    },
};
