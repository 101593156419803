const bonuses = {
    state: {
        bonuses: {},
        bonus: {},
    },

    mutations: {
        setBonuses: (state, payload) => {
            state.bonuses = payload;
        },
        setBonus: (state, payload) => {
            state.bonus = payload;
        },
    },

    getters: {
        getBonuses: (state) => {
            return state.bonuses;
        },
        getBonus: (state) => {
            return state.bonus;
        },
    },

    actions: {
        setBonuses({ commit }, data) {
            commit("setBonuses", data);
        },

        setBonus({ commit }, data) {
            commit("setBonus", data);
        },

        clearBonuses({commit}) {
            commit('setBonuses', {});
        },

        clearBonus({commit}) {
            commit('setBonus', {});
        }
    },
};

export { bonuses };
