const profile = {
    state: {
        profile: {},
    },

    mutations: {
        setUserProfile: (state, payload) => {
            state.profile = payload;
        },
        setFirstName: (state, payload) => {
            state.name = payload;
        },
        setPhone: (state, payload) => {
            state.phone = payload;
        }
    },

    getters: {
        getUserProfile: (state) => {
            return state.profile;
        },
        getUserFirstName: (state) => {
            return state.name;
        },
        getUserPhone: (state) => {
            return state.phone;
        },
    },

    actions: {
        setUserProfile({ commit }, data) {
            commit("setUserProfile", data);
        },
        setFirstName({ commit }, data) {
            commit("setFirstName", data);
        },
        setPhone({ commit }, data) {
            commit("setPhone", data);
        },

        clearUserProfile({commit}) {
            commit('setUserProfile', {})
        }
    },
};

export { profile };
