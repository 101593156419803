const review = {
    state: {
        review: {},
    },

    mutations: {
        setReview: (state, payload) => {
            state.review = payload.review;
        },
    },

    getters: {
        getReview: (state) => {
            return state;
        },
    },

    actions: {
        setReview({ commit }, data) {
            commit("setReview", {
                review: data,
            });
        },

        clearReview({ commit }) {
            commit("setReview", {});
        },
    },
};

export { review };
