const activityStatus = {
    state: {
        activityStatus: {},
    },

    mutations: {
        setDashboardActivityStatus: (state, activityStatus) => {
            state.activityStatus = activityStatus;
        },
    },

    getters: {
        getDashboardActivityStatus: (state) => {
            return state.activityStatus;
        },
    },

    actions: {
        setDashboardActivityStatus({ commit }, data) {
            commit("setDashboardActivityStatus", {
                activityStatus: data,
            });
        },

        clearDashboardActivityStatus({ commit }) {
            commit("setDashboardActivityStatus", {});
        },
    },
};

export { activityStatus };
