const custom = {
    state: {
        selectedAccessLevel: {},
    },

    mutations: {
        setSelectedAccessLevel: (state, payload) => {
            state.selectedAccessLevel = payload;
        },
    },

    getters: {
        getSelectedAccessLevel: (state) => {
            return state.selectedAccessLevel;
        },
    },

    actions: {
        setSelectedAccessLevel({commit}, data) {
            commit("setSelectedAccessLevel", data);
        },

        clearSelectedAccessLevel({commit}) {
            commit('setSelectedAccessLevel', {});
        }
    },
};

export {custom};
