export const alert = {
    state: {
        alert: {
            isActive: false,
            type: "",
            text: "",
        },
        endTime: "",
        alertTimeout: "",
    },

    mutations: {
        setAlert: (state, data) => {
            state.alert = data.alert;

            clearTimeout(state.alertTimeout);
            state.alertTimeout = setTimeout(
                () =>
                    (state.alert = {
                        isActive: false,
                        type: "",
                        text: "",
                    }),
                data.alert.endTime ? data.alert.endTime : 3000
            );
        },
    },

    getters: {
        getAlert: (state) => {
            return state.alert;
        },
    },

    actions: {
        setAlert({ commit }, data) {
            commit("setAlert", {
                alert: data.alert,
            });
        },

        clearAlert({ commit }) {
            commit("setAlert", {
                alert: {
                    isActive: false,
                    type: "",
                    text: "",
                },
            });
        },
    },
};
