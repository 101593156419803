export default [
    {
        path: "/404",
        name: "404",
        component: () => import("@/views/pages/utility/404/404"),
    },
    // Redirect any unmatched routes to the 404 page. This may
    // require some server configuration to work in production:
    // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
    {
        path: "*",
        redirect: "404",
    },

    {
        path: "/",
        name: "Магазин",
        component: () => import("@/views/pages/shop/shop"),
    },

    {
        path: "/500",
        name: "500",
        component: () => import("@/views/pages/utility/500/500"),
    },

    {
        path: "/notifications",
        name: "Уведомления",
        meta: { authRequired: true },
        component: () =>
            import("@/views/partials/MainHeader/notificationCenter/notificationCenter"),
    },

    {
        path: "/profile",
        component: { render: (c) => c("router-view") },
        meta: { authRequired: true },
        children: [
            {
                path: "",
                name: "Профиль",
                component: () => import("@/views/pages/profile/profileMain/profileMain"),
            },
            {
                path: "notifications",
                name: "Профиль – Уведомления",
                component: () =>
                    import(
                        "@/views/pages/profile/profileNotifications/profileNotifications"
                    ),
            },
            {
                path: "password",
                name: "Профиль – Смена пароля",
                component: () =>
                    import("@/views/pages/profile/profilePassword/profilePassword"),
            },
        ],
    },

    {
        path: "/:slug",
        component: () => import("@/views/pages/course/course"),
        meta: { authRequired: true },
        children: [
            {
                path: "",
                redirect: "/:slug/dashboard",
            },
            {
                path: "knowledge-base",
                component: { render: (c) => c("router-view") },
                children: [
                    {
                        path: "",
                        name: "База знаний",
                        component: () =>
                            import("@/views/pages/knowledgeBase/knowledgeBase"),
                    },
                    {
                        path: "inner",
                        name: "База знаний/разделы",
                        component: () =>
                            import(
                                "@/views/pages/knowledgeBase/knowledgeBaseSection/knowledgeBaseSection"
                            ),
                    },
                    {
                        path: "single",
                        name: "База знаний/разделы/статья",
                        component: () =>
                            import(
                                "@/views/pages/knowledgeBase/knowledgeBaseArticle/knowledgeBaseArticle"
                            ),
                    },
                    {
                        path: "search",
                        name: "База знаний/поиск",
                        component: () =>
                            import(
                                "@/views/pages/knowledgeBase/knowledgeBaseSearch/knowledgeBaseSearch"
                            ),
                    },
                ],
            },
            {
                path: "reviews",
                meta: { reviewsEnabled: true },
                component: { render: (c) => c("router-view") },
                children: [
                    {
                        path: "",
                        name: "Отзывы",
                        component: () => import("@/views/pages/reviews/reviews"),
                    },
                    {
                        path: "write-review",
                        name: "Отзывы / Написать отзыв",
                        component: () =>
                            import("@/views/pages/reviews/writeReview/writeReview"),
                    },
                ],
            },
            {
                path: "webinars",
                meta: { webinarsEnabled: true },
                component: { render: (c) => c("router-view") },
                children: [
                    {
                        path: "",
                        name: "Вебинары",
                        component: () => import("@/views/pages/webinars/webinars"),
                    },
                    {
                        path: "recording/:webinar_id",
                        name: "Вебинары / запись вебинара",
                        component: () =>
                            import(
                                "@/views/pages/webinars/webinarsRecording/webinarsRecording"
                            ),
                    },
                    {
                        path: "live",
                        name: "Трансляция",
                        component: () =>
                            import("@/views/pages/webinars/webinarsLive/webinarsLive"),
                    },
                ],
            },
            {
                path: "study",
                meta: { studyEnabled: true },
                component: { render: (c) => c("router-view") },
                children: [
                    {
                        path: "",
                        name: "Обучение",
                        component: () => import("@/views/pages/study/study"),
                    },
                    {
                        path: ":block_priority/comments",
                        name: "Обучение / комментарии",
                        component: () =>
                            import(
                                "@/views/pages/study/studyCommentSection/studyCommentSection"
                            ),
                    },
                    {
                        path: ":block_priority/:lesson_id",
                        component: () =>
                            import("@/views/pages/study/studyLesson/studyLesson"),
                        children: [
                            {
                                path: "",
                                name: "Урок",
                                component: () =>
                                    import(
                                        "@/views/pages/study/studyLesson/lesson/lesson"
                                    ),
                            },
                            {
                                path: "homework",
                                name: "Домашнее задание",
                                component: () =>
                                    import("@/views/pages/study/studyLesson/test/test"),
                            },
                            {
                                path: "algorithm",
                                name: "Алгоритм",
                                component: () =>
                                    import(
                                        "@/views/pages/study/studyLesson/algorithm/algorithm"
                                    ),
                            },
                            {
                                path: "evaluation",
                                name: "Оценка урока",
                                component: () =>
                                    import(
                                        "@/views/pages/study/studyLesson/lessonEvaluation/lessonEvaluation"
                                    ),
                            },
                            {
                                path: "correct-answer",
                                name: "Правильный ответ",
                                component: () =>
                                    import(
                                        "@/views/pages/study/studyLesson/specialTasks/correctAnswer/correctAnswer"
                                    ),
                            },
                            {
                                path: "estimate",
                                name: "Оценка ответа других студентов",
                                component: () =>
                                    import(
                                        "@/views/pages/study/studyLesson/specialTasks/specialTasksGrade/specialTasksGrade"
                                    ),
                            },
                            {
                                path: "your-grade",
                                name: "Оценка Вашего ответа",
                                component: () =>
                                    import(
                                        "@/views/pages/study/studyLesson/specialTasks/userSpecialTasksGrade/userSpecialTasksGrade"
                                    ),
                            },
                        ],
                    },
                ],
            },
            {
                path: "questions",
                name: "Вопрос куратору",
                meta: { questionsEnabled: true },
                component: () => import("@/views/pages/study/studyChat/studyChat"),
            },
            {
                path: "dashboard",
                component: { render: (c) => c("router-view") },
                children: [
                    {
                        path: "",
                        name: "Дашборд",
                        component: () => import("@/views/pages/dashboard/dashboard"),
                    },
                    {
                        path: "packages",
                        name: "Пакеты участия",
                        component: () =>
                            import(
                                "@/views/pages/dashboard/participationPackages/participationPackages"
                            ),
                    },
                    {
                        path: "shop",
                        name: "dashboard_shop",
                        component: () =>
                            import("@/views/pages/dashboard/dashboardShop/dashboardShop"),
                    },
                    {
                        path: "achievements",
                        name: "Достижения",
                        component: () =>
                            import(
                                "@/views/pages/dashboard/dashboardAchievements/dashboardAchievements"
                            ),
                    },
                    {
                        path: "survey",
                        name: "Опрос",
                        component: () =>
                            import(
                                "@/views/pages/dashboard/dashboardSurvey/dashboardSurvey"
                            ),
                    },
                    {
                        path: "opening-test",
                        name: "Вступительный тест",
                        component: () =>
                            import("@/views/pages/dashboard/dashboardTest/dashboardTest"),
                    },
                    {
                        path: "final-test",
                        name: "Финальный тест обучения",
                        component: () =>
                            import("@/views/pages/dashboard/dashboardTest/dashboardTest"),
                    },
                    {
                        path: "tests-comparison",
                        name: "Сравнение результатов",
                        component: () =>
                            import(
                                "@/views/pages/dashboard/dashboardTest/comparisonResult/comparisonResult"
                            ),
                    },
                ],
            },
            {
                path: "bonuses",
                meta: { bonusesEnabled: true },
                component: { render: (c) => c("router-view") },
                children: [
                    {
                        path: "",
                        name: "Бонусы",
                        component: () => import("@/views/pages/bonuses/bonuses"),
                    },
                    {
                        path: "read/:id",
                        name: "bonus_read",
                        component: () =>
                            import("@/views/pages/bonuses/lectureNotes/lectureNotes"),
                    },
                    {
                        path: "watch/:id",
                        name: "bonus_watch",
                        component: () =>
                            import("@/views/pages/bonuses/bonusWebinars/bonusWebinars"),
                    },
                    {
                        path: "watch/:id/video/:video_id",
                        name: "bonus_video",
                        component: () => import("@/views/pages/bonuses/bonus/bonus"),
                    },
                ],
            },
            {
                path: "certificate",
                name: "certificate",
                component: () => import("@/views/pages/certificate/certificate"),
            },
            {
                path: "error-request",
                name: "Запрос об ошибке",
                component: () => import("@/views/pages/errorRequest/errorRequest"),
            },
        ],
    },
];
