const chat = {
    state: {
        chat: {},
    },

    mutations: {
        setChat: (state, payload) => {
            state.chat = payload.chat;
        },
    },

    getters: {
        getChat: (state) => {
            return state;
        },
    },

    actions: {
        setChat({ commit }, data) {
            commit("setChat", {
                chat: data,
            });
        },

        clearChat({ commit }) {
            commit("setChat", {});
        },
    },
};

export { chat };
