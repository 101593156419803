export const evaluation = {
    state: {
        evaluation: {},
    },

    mutations: {
        setEvaluation: (state, data) => {
            state.evaluation = data;
        },
    },

    getters: {
        getEvaluation: (state) => {
            return state.evaluation;
        },
    },

    actions: {
        setEvaluation({ commit }, data) {
            commit("setEvaluation", {
                evaluation: data,
            });
        },

        clearEvaluation({ commit }) {
            commit("setEvaluation", {
                evaluation: [],
            });
        },
    },
};
